import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { documentReady, getStringFromElement, stringToSlug, useScrollPosition } from "../utils/gatsby-helpers"
import Layout from "../components/layout"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import Seo from "../components/seo"
import scrollTo from "gatsby-plugin-smoothscroll"

const ContractingConditions = ({ data, pageContext }) => {
    const page = data.page.edges[0].node

    //let isScrolling = false
    let indexItems = []
    //let positions = []

    if (typeof window !== `undefined` && typeof document !== `undefined`) {
        const e = document.createElement("div")
        const o = document.createElement("div")

        e.innerHTML = page.description_one

        e.querySelectorAll("h3").forEach((element, index) => {
            indexItems.push(element.innerText)
        })

        o.innerHTML = page.description_two

        o.querySelectorAll("h3").forEach((element, index) => {
            indexItems.push(element.innerText)
        })
    }

    const [sectionSelected, setSectionSelected] = useState(null)

    /*const selectSection = (section) => {
        isScrolling = true

        setSectionSelected(section)

        scrollTo(`#${section}`)

        setTimeout(() => {
            isScrolling = false
        }, 3000)
    }*/

    const elements = {
        h3: ({ node, ...props }) => {
            const element = <h2 {...props} />
            const stringElement = getStringFromElement(element)

            return <h3 id={"item-" + stringToSlug(stringElement)} {...props} />
        },
    }

    /*documentReady(() => {
        indexItems.forEach((id) => {
            const element = document.getElementById("item-" + stringToSlug(id))
            const rect = element.getBoundingClientRect()

            positions.push({ id: "item-" + stringToSlug(id), top: rect.y - 80 })
        })

        positions.sort((a,b) => b.top - a.top);
    })*/

    /*useScrollPosition((p) => {
        if (!isScrolling) {
            const top = Math.abs(p.currentPosition.y)

            positions.every((position) => {
                if (top >= position.top) {
                    setSectionSelected(position.id)

                    return false
                }

                return true
            })
        }
    })*/

    return (
        <Layout pageContext={pageContext}>
            <Seo lang={pageContext.langKey} title={page.seo.title} description={page.seo.meta_description} translates={pageContext.translates} />

            <main className="main">
                <div className="main__section main__section--20-80">
                    <div className="container">
                        <div className="hidden-lg">
                            <div className="container__index">
                                {indexItems.map((item, index) => {
                                    const slug = "#item-" + stringToSlug(item)
                                    return   <a href={`#${stringToSlug(slug)}`}>{item}</a>
                                })}
                            </div>
                        </div>
                        <div className="container__text">
                            <h1 className="merriweather">{page.title}</h1>
                            <div className="container__text__text">
                                <ReactMarkdown children={page.description_one} rehypePlugins={[rehypeRaw]} components={elements} />
                                <div className="list-table">
                                    {page.items.map((item, index) => (
                                        <div key={index} className="list-table__item">
                                            <h6>{item.title}</h6>
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    ))}
                                </div>
                                <ReactMarkdown children={page.description_two} rehypePlugins={[rehypeRaw]} components={elements} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default ContractingConditions;

export const contractingConditionsPageQuery = graphql`
query($langKey: String) {
    page: allStrapiLegalCondicionesGeneralesDeContratacion (
        filter: { locale: { eq: $langKey } }
    ) {
        edges {
            node {
                id,
                seo {
                    title,
                    meta_description
                }
                title
                description_one
                items {
                    order
                    title
                    description
                }
                description_two
            }
        }
    }
}`;
